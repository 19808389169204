import React, {useEffect, useRef, useState} from 'react';
import { useAppContext } from '../libs/contextLib';
import * as Bootstrap from "react-bootstrap";
import Accordion from 'react-bootstrap/Accordion';
import { useDraggable } from "react-use-draggable-scroll";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import * as faIcon from '@fortawesome/free-solid-svg-icons';
import { Swiper, SwiperSlide } from 'swiper/react';
import { Navigation } from 'swiper';
import { useSwiper } from 'swiper/react';
import 'swiper/css';
import "swiper/swiper-bundle.css";
import "swiper/css/pagination";
import "swiper/css/autoplay";
import 'swiper/css/free-mode';

import CitiImage from "../assets/images/citi-image.png"

export default function CaseStudiesSlides(props) {
	// const { isMobileView } = useAppContext();

    const [swiper, setSwiper] = React.useState(null);
    const [currSlide, setCurrSlide] = React.useState(0);
    const nextSlide = () => {swiper.slideNext();};
    const prevSlide = () => {swiper.slidePrev();};

    useEffect(() => {
        
    }, []);

    const ourRef = useRef(null);
    const [isMouseDown, setIsMouseDown] = useState(false);
    const mouseCoords = useRef({
        startX: 0,
        startY: 0,
        scrollLeft: 0,
        scrollTop: 0
    });
    const [isScrolling, setIsScrolling] = useState(false);
    const handleDragStart = (e) => {
        if (!ourRef.current) return
      const slider = ourRef.current.children[0];
        const startX = e.pageX - slider.offsetLeft;
        const startY = e.pageY - slider.offsetTop;
        const scrollLeft = slider.scrollLeft;
        const scrollTop = slider.scrollTop;
        mouseCoords.current = { startX, startY, scrollLeft, scrollTop }
        setIsMouseDown(true)
        document.body.style.cursor = "grabbing"
    }
    const handleDragEnd = () => {
        setIsMouseDown(false)
        if (!ourRef.current) return
        document.body.style.cursor = "default"
    }
    const handleDrag = (e) => {
        if (!isMouseDown || ! ourRef.current) return;
        e.preventDefault();
        const slider = ourRef.current.children[0];
        const x = e.pageX - slider.offsetLeft;
        const y = e.pageY - slider.offsetTop;
        const walkX = (x - mouseCoords.current.startX) * 1.5;
        const walkY = (y - mouseCoords.current.startY) * 1.5;
        slider.scrollLeft = mouseCoords.current.scrollLeft - walkX;
        slider.scrollTop = mouseCoords.current.scrollTop - walkY;
    }
    
    return(
        <>
            <div className={`case-study ${props.project}`}>
                <Swiper
                    spaceBetween={0}
                    slidesPerView={1}
                    // simulateTouch={false}
                    // // noSwiping={false}
                    // autoHeight={(props.project=="cornetto")?true:false}
                    onSwiper={(s) => {
                        setSwiper(s);
                    }}
                    onActiveIndexChange={(s) => { 
                        setCurrSlide(s.activeIndex);
                    }}
                    >
                    <SwiperSlide>
                        <div className="media-wrapper">
                            {props.media}
                        </div>
                    </SwiperSlide>
                    {props.gallery != undefined &&
                        <SwiperSlide>
                            {/* <div className="gallery" ref={ourRef} onMouseDown={handleDragStart} onMouseUp={handleDragEnd}> */}
                                <div className="gallery">
                                    {props.gallery}
                                </div>
                            {/* </div> */}
                        </SwiperSlide>
                    }
                </Swiper>
                <div className="article">
                    {props.article}
                </div>
                {props.gallery != undefined &&
                    <>{currSlide ?
                        <div className="next-slide prev-slide">
                            <div className='line'></div>
                            <span className='arrow' onClick={prevSlide}><FontAwesomeIcon icon={faIcon.faArrowRight}/></span>
                        </div>
                        :
                        <div className="next-slide">
                            <div className='line'></div>
                            <span className='arrow' onClick={nextSlide}><FontAwesomeIcon icon={faIcon.faArrowRight}/></span>
                        </div>
                    }</>
                }   
            </div>
        </>
    );
}