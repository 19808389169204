import React, {useEffect, useState} from 'react';
import { useAppContext } from '../libs/contextLib';
import { Link } from "react-router-dom";
import * as Bootstrap from "react-bootstrap";
import Nav from 'react-bootstrap/Nav';
import Navbar from 'react-bootstrap/Navbar';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import * as faIcon from '@fortawesome/free-solid-svg-icons';
import * as faBrands from "@fortawesome/free-brands-svg-icons";


import logo from "../assets/images/1dmgLogo-light.png"
import logoBlue from "../assets/images/1dmgLogo-blue.png"
import Brief from './Brief';

export default function Header() {
	const { isMobileView } = useAppContext();
    
    const [isNavOpen, setNavOpen] = useState(false);
    const [closingNav, setClosingNav] = useState(false);

    const [isServicesVisible, setServicesVisible] = useState(false);
    const [isCaseStudyVisible, setCaseStudyVisble] = useState(false);
    const [isServicesVisibleMobile, setServicesVisibleMobile] = useState(false);
    const [isCaseStudyVisibleMobile, setCaseStudyVisbleMobile] = useState(false);

    const handleMouseEnter = (target) => {
        switch (target) {
            case "services": setServicesVisible(true) 
                break;
            case "casestudies": setCaseStudyVisble(true) 
                break;
        }
    };

    const handleMouseLeave = (target) => {
        switch (target) {
            case "services": setServicesVisible(false) 
                break;
            case "casestudies": setCaseStudyVisble(false) 
                break;
        }
    };

    function handleNavClick(target) {
        switch (target) {
            case "services": setServicesVisibleMobile(!isServicesVisibleMobile)
                break;
            case "casestudies": setCaseStudyVisbleMobile(!isCaseStudyVisibleMobile) 
                break;
        }
    }

    useEffect(() => {
    }, [isMobileView])

    function openNav(e) {
        setNavOpen(true);
    }

    function closeNav(e) {
        setClosingNav("close");
        setTimeout(() => {
            setNavOpen(false);
            setClosingNav("");
        }, 300);
    }

    function reload() {
        // setTimeout(() => {
        //     window.location.reload()
        // }, 50);
    }
    
    return(
        <>
            <header className={isNavOpen ? "nav-open" : ""}>
                <a href="/" className="header-logo">
                    <img src={isNavOpen ? logoBlue : logo} alt="1DMG Logo"/>
                </a>
                <div className="right">
                    {!isMobileView && 
                        <ul className='desktop-nav'>
                            <li onMouseEnter={()=>handleMouseEnter("casestudies")}
                                onMouseLeave={()=>handleMouseLeave("casestudies")}>
                                <a href="#case-study" >Case Study</a>
                                {isCaseStudyVisible && 
                                    <ul className='sub-menu'>
                                        <li><a href="/#citi"/*onClick={reload}*/>Citi</a></li>
                                        <li><a href="/#kumon" /*onClick={reload}*/>Kumon</a></li>
                                        <li><a href="/#babydove" /*onClick={reload}*/>Baby Dove</a></li>
                                        <li><a href="/#unmeat" /*onClick={reload}*/>UnMEAT</a></li>
                                        <li><a href="/#cornetto" /*onClick={reload}*/>Cornetto</a></li>
                                    </ul>
                                }
                            </li>
                            <li onMouseEnter={()=>handleMouseEnter("services")}
                                onMouseLeave={()=>handleMouseLeave("services")}>
                                <a href="#services" >Services</a>
                                {isServicesVisible && 
                                    <ul className='sub-menu'>
                                        <li><a href="/#strategy" /*onClick={reload}*/>strategy</a></li>
                                        <li><a href="/#design" /*onClick={reload}*/>creative services</a></li>
                                        <li><a href="/#project-development" /*onClick={reload}*/>project management</a></li>
                                        <li><a href="/#innovations" /*onClick={reload}*/>innovations & development</a></li>
                                        <li><a href="/#influencer-marketing" /*onClick={reload}*/>influencer marketing</a></li>
                                        <li><a href="/#ecommerce" /*onClick={reload}*/>e-commerce enablement</a></li>
                                        <li><a href="/#video-production" /*onClick={reload}*/>video production</a></li>
                                        <li><a href="/#customer-support" /*onClick={reload}*/>customer support</a></li>
                                        <li><a href="/#media-buying" /*onClick={reload}*/>media buying and programmatic</a></li>
                                        <li><a href="/#tiktok" /*onClick={reload}*/>tiktok brand starter pack</a></li>
                                    </ul>
                                }
                            </li>
                        </ul>
                    }
                    <span className="menu-hamburger" onClick={isNavOpen ? closeNav : openNav}>
                        {/* <FontAwesomeIcon icon={faIcon.faBars}/> */}
                        <div className="bars">
                            <div></div>
                            <div></div>
                            <div></div>
                        </div>
                    </span>
                </div>
            </header>

            {isNavOpen &&
                <nav className='nav'>
                    <div className={`backdrop ${closingNav}`} onClick={closeNav}></div>
                    <div className={`menu-nav ${closingNav}`}>
                        <header className='nav-open'>
                            <a href="/" className="header-logo">
                                <img src={logoBlue} alt="1DMG Logo Blue"/>
                            </a>
                            <span className="menu-hamburger" onClick={closeNav}>
                                <div className="bars">
                                    <div></div>
                                    <div></div>
                                    <div></div>
                                </div>
                            </span>
                        </header>
                        <nav>
                            <ul>
                                <li>
                                    <a href="#about"  onClick={closeNav}>about</a>
                                </li>
                                {isMobileView &&<>
                                    <li 
                                        // onMouseEnter={()=>handleMouseEnter("casestudies")}
                                        // onMouseLeave={()=>handleMouseLeave("casestudies")}
                                        onClick={()=>handleNavClick("casestudies")}>
                                        <a>case study</a>
                                        {isCaseStudyVisibleMobile && 
                                            <ul className='sub-menu'>
                                                <li><a href="/#citi"/*onClick={reload}*/>citi</a></li>
                                                <li><a href="/#kumon" /*onClick={reload}*/>kumon</a></li>
                                                <li><a href="/#babydove" /*onClick={reload}*/>baby dove</a></li>
                                                <li><a href="/#unmeat" /*onClick={reload}*/>unmeat</a></li>
                                                <li><a href="/#cornetto" /*onClick={reload}*/>cornetto</a></li>
                                            </ul>
                                        }
                                    </li>
                                    <li 
                                        // onMouseEnter={()=>handleMouseEnter("services")}
                                        // onMouseLeave={()=>handleMouseLeave("services")}
                                        onClick={()=>handleNavClick("services")}>
                                        <a>services</a>
                                        {isServicesVisibleMobile && 
                                            <ul className='sub-menu'>
                                                <li><a href="/#strategy" /*onClick={reload}*/>strategy</a></li>
                                                <li><a href="/#design" /*onClick={reload}*/>creative services</a></li>
                                                <li><a href="/#project-development" /*onClick={reload}*/>project management</a></li>
                                                <li><a href="/#innovations" /*onClick={reload}*/>innovations & development</a></li>
                                                <li><a href="/#influencer-marketing" /*onClick={reload}*/>influencer marketing</a></li>
                                                <li><a href="/#ecommerce" /*onClick={reload}*/>e-commerce enablement</a></li>
                                                <li><a href="/#video-production" /*onClick={reload}*/>video production</a></li>
                                                <li><a href="/#customer-support" /*onClick={reload}*/>customer support</a></li>
                                                <li><a href="/#media-buying" /*onClick={reload}*/>media buying and programmatic</a></li>
                                                <li><a href="/#tiktok" /*onClick={reload}*/>tiktok brand starter pack</a></li>
                                            </ul>
                                        }
                                    </li>
                                </>}
                                <li>
                                    <a href="#contact"  onClick={closeNav}>contact us</a>
                                </li>
                            </ul>
                        </nav>
                        <div className="nav-footer">
                            <div className="socials">
                                <a href="https://www.facebook.com/onedmg/"><FontAwesomeIcon icon={faBrands.faFacebookF}/></a>
                                <a href="https://www.linkedin.com/company/1-digital-media-group-1dmg-"><FontAwesomeIcon icon={faBrands.faLinkedinIn}/></a>
                                <a href="https://www.instagram.com/1dmgph"><FontAwesomeIcon icon={faBrands.faInstagram}/></a>
                            </div>
                            <Brief for={"header"}/>
                        </div>
                    </div>
                </nav>
            }
        </>
    );
}