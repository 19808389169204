import React, {useEffect, useState} from 'react';
import { useLocation } from "react-router-dom";
import { useAppContext } from '../libs/contextLib';
import * as Bootstrap from "react-bootstrap";
import Accordion from 'react-bootstrap/Accordion';
import { useAccordionButton } from 'react-bootstrap/AccordionButton';
import ScrollContainer from 'react-indiana-drag-scroll';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import * as faIcon from '@fortawesome/free-solid-svg-icons';
import { Swiper, SwiperSlide } from 'swiper/react';
import { Navigation } from 'swiper';
import { useSwiper } from 'swiper/react';
import 'swiper/css';
import "swiper/swiper-bundle.css";
import "swiper/css/pagination";
import "swiper/css/autoplay";
import 'swiper/css/free-mode';

import LightGallery from 'lightgallery/react';
import 'lightgallery/css/lightgallery.css';
import 'lightgallery/css/lg-video.css';
import 'lightgallery/css/lg-zoom.css';
import 'lightgallery/css/lg-thumbnail.css';
import lgThumbnail from 'lightgallery/plugins/thumbnail';
import lgZoom from 'lightgallery/plugins/zoom';
import lgVideo from 'lightgallery/plugins/video';

import placeholderImg from "../assets/images/placeholder-img.png"
import placeholderImg1 from "../assets/images/placeholder-img-1.png"
import CitiImage from "../assets/images/citi-image.png"
import KumonImage from "../assets/images/kumon-image.png"
import UnmeatImage from "../assets/images/unmeat-image.png"
import BabyDoveImage from "../assets/images/babydove-image.png"

import cornettoVideo from "../assets/case-study-media/cornetto/Cornetto2018_CONEFessions_Bes_.mp4";
import CornettoImage from "../assets/images/cornetto-image.png";
import unmeatVideo from "../assets/case-study-media/unmeat/UnMeat2021_PTVCs_Burger10s_1920x1080_FA.mp4";
import unmeatImage from "../assets/images/UnmeatImage.jpg";
import kumonVideo from "../assets/case-study-media/kumon/Kumon2023_FR_OCT_CONCIRGE_3_FA.mp4";
import KumonImagethumb from "../assets/images/KumonImage.png";
import babyDoveVideo from "../assets/case-study-media/babydove/DOVEBABY2018_AWARENESSAD_360Video_800x1000_FA_09102018.mp4";
import babyDovethumb from "../assets/images/babydovethumb.jpg";
import babyDoveVideo2 from "../assets/case-study-media/babydove/DOVEBABY2018_CONTENT_JAN_Purchase_Carousel_Q1_Part_1080x1080.mp4";
import babyDovethumb2 from "../assets/images/babydovethumb-2.png";

import babyDoveTR from "../assets/case-study-media/babydove/DoveSimba2018_MomZIlla_March_C.jpg"
import kumonLM from "../assets/case-study-media/kumon/Kumon2022_FR_SEPT_EXPO_KV_FA.jpg"
import unmeatRM from "../assets/case-study-media/unmeat/UnMeat2021_IGHighlights_FAQs_Ingredients_Burger_FA.jpg";
import unmeatBL from "../assets/case-study-media/unmeat/UnMeat2021_July_ProductInfo_1080x1080_FA.jpg";

import CaseStudiesSlides from './CaseStudiesSlides';

export default function CaseStudies(props) {
	// const { isMobileView } = useAppContext();
    const { hash } = useLocation();
    const [openTab, setOpenTab] = useState(hash.split("#")[1])

    function getOffset( el ) {
        var _x = 0;
        var _y = 0;
        while( el && !isNaN( el.offsetLeft ) && !isNaN( el.offsetTop ) ) {
            _x += el.offsetLeft - el.scrollLeft;
            _y += el.offsetTop - el.scrollTop;
            el = el.offsetParent;
        }
        return { top: _y, left: _x };
    }

    useEffect(() => {
        if(hash){
            const el = document.querySelector(hash);
            setOpenTab(hash.split("#")[1]);
            setTimeout(() => {
                window.scrollTo({
                    top: getOffset(el).top - 30,
                    behavior: 'smooth'
                });
            }, 500);
        }
    }, [hash])
    
    return(
        <>
        <Accordion defaultActiveKey={props.openTab} activeKey={openTab}>
            <Accordion.Item eventKey="citi" id='citi'>
                <Accordion.Header onClick={()=>setOpenTab("citi")}>
                    <div className="trigger-wrapper">
                        <h2>CITI</h2>
                        <div className="industry">
                            <small>Industry</small><br />
                            <span>Finance</span>
                        </div>
                    </div>
                </Accordion.Header>
                <Accordion.Body>
                    <CaseStudiesSlides 
                        project={"citi"}
                        media={<>
                            <img src={CitiImage} alt="People talking on the table"/>
                        </>}
                        article={<>
                            <h4>CHALLENGE</h4>
                            <p>To connect Citi with its community, and deliver a new customer service alternative for its clients</p>
                            <h4>SOLUTION</h4>
                            <p>We designed, implemented, and maintained Citi's community management system, pioneering processes for inquiries, escalations, reporting and insighting, ensuring a consistent, streamlined CX for the brand.</p>
                            <div className="tags">
                                <span>Brand Strategy</span>
                                <span>Community Management</span>
                            </div>
                        </>}
                        gallery={undefined}
                    />
                </Accordion.Body>
            </Accordion.Item>
            <Accordion.Item eventKey="cornetto" id='cornetto'>
                <Accordion.Header onClick={()=>setOpenTab("cornetto")}>
                    <div className="trigger-wrapper overflow">
                        <h2>CORNETTO</h2>
                        <div className="industry">
                            <small>Industry</small><br />
                            <span>Food & Beverage</span>
                        </div>
                    </div>
                </Accordion.Header>
                <Accordion.Body>
                    <CaseStudiesSlides 
                        project={"cornetto"}
                        media={<>
                            <LightGallery
                                speed={500}
                                plugins={[lgThumbnail, lgVideo]}
                            >
                                <a className='case-study-gallery-item'
                                    data-lg-size="1280-720"
                                    data-src='https://youtube.com/watch?v=uPnTWC3GiPQ'>
                                    <img src="https://1dmg-website.s3.ap-southeast-1.amazonaws.com/case-study-media/cornetto/Cornetto2018_CONEFessions_Bes_.png"/>
                                </a>
                            </LightGallery>
                        </>}
                        article={<>
                            <h4>CHALLENGE</h4>
                            <p>To establish Cornetto’s equity as the top brand in the “kilig” space</p>
                            <h4>SOLUTION</h4>
                            <p>We developed a highly thematic campaign centered around the idea of repurposing our lids as the ultimate device to help you and your crush break the ice through funny jokes and kilig lines. We kicked off the redesigned product experience with a series of short video stories highlighting cute, kilig, heartwarming and highly relatable “Cone-fessions.”</p>
                            <div className="tags">
                                <span className='blue'>Brand Strategy</span>
                                <span className='blue'>Social Media Strategy</span>
                            </div>
                        </>}
                        gallery={<>
                                <LightGallery
                                    speed={500}
                                    plugins={[lgThumbnail, lgVideo]}
                                >
                                    <a className='case-study-gallery-item left-top'
                                        href="https://1dmg-website.s3.ap-southeast-1.amazonaws.com/case-study-media/cornetto/Cornetto2018_Valentine's_CONEfess_A.jpg">
                                        <img src="https://1dmg-website.s3.ap-southeast-1.amazonaws.com/case-study-media/cornetto/Cornetto2018_Valentine's_CONEfess_A.jpg"/>
                                    </a>
                                    <a className='case-study-gallery-item right-most'
                                        data-lg-size="1280-720"
                                        data-src={`https://youtube.com/watch?v=CM3YE6ZU7zA`}>
                                        <img src="https://1dmg-website.s3.ap-southeast-1.amazonaws.com/case-study-media/cornetto/Cornetto2018_Valentine's_CutieSpotting_320x640_A.png"/>
                                    </a>
                                    {/* <a className='case-study-gallery-item'
                                        data-lg-size="1280-720"
                                        data-src='{"source": [{"src":"https://1dmg-website.s3.ap-southeast-1.amazonaws.com/case-study-media/cornetto/Cornetto2018_CONEFessions_Bes_.mp4", "type":"video/mp4"}], "attributes": {"preload": "false", "controls": true}}'>
                                        <img src="https://1dmg-website.s3.ap-southeast-1.amazonaws.com/case-study-media/cornetto/Cornetto2018_CONEFessions_Bes_.png"/>
                                    </a> */}

                                    <a className='case-study-gallery-item left-right'
                                        data-lg-size="1280-720"
                                        data-src='https://youtube.com/watch?v=JyySdsdWtHw'>
                                        <img src="https://1dmg-website.s3.ap-southeast-1.amazonaws.com/case-study-media/cornetto/CORNETTO2018_CONEFESSIONS_LIDLAUNCH_Translation_D.png"/>
                                    </a>
                                    <a className='case-study-gallery-item left-left'
                                        data-lg-size="1280-720"
                                        data-src='https://youtube.com/watch?v=WFfG--9KHlo'>
                                        <img src="https://1dmg-website.s3.ap-southeast-1.amazonaws.com/case-study-media/cornetto/CORNETTO2018_CONEFESSIONS_CONSIDERATION_1080X1080_01_D.png"/>
                                    </a>
                                    {/* <a className='case-study-gallery-item'
                                        data-lg-size="1280-720"
                                        data-src='{"source": [{"src":"https://1dmg-website.s3.ap-southeast-1.amazonaws.com/case-study-media/cornetto/CORNETTO2018_CONEFESSIONS_CONSIDERATIONAD_Hintayan_D(Girl).mp4", "type":"video/mp4"}], "attributes": {"preload": "false", "controls": true}}'>
                                        <img src="https://1dmg-website.s3.ap-southeast-1.amazonaws.com/case-study-media/cornetto/CORNETTO2018_CONEFESSIONS_CONSIDERATIONAD_Hintayan_D(Girl).png"/>
                                    </a> */}
                                </LightGallery>
                        </>}
                    />
                </Accordion.Body>
            </Accordion.Item>
            <Accordion.Item eventKey="kumon" id='kumon'>
                <Accordion.Header onClick={()=>setOpenTab("kumon")}>
                    <div className="trigger-wrapper">
                        <h2>KUMON</h2>
                        <div className="industry">
                            <small>Industry</small><br />
                            <span>Education</span>
                        </div>
                    </div>
                </Accordion.Header>
                <Accordion.Body>
                    <CaseStudiesSlides 
                        project={"kumon"}
                        media={<>
                            <LightGallery
                                speed={500}
                                plugins={[lgThumbnail, lgVideo]}
                            >
                                <a className='case-study-gallery-item right-most'
                                    href="https://1dmg-website.s3.ap-southeast-1.amazonaws.com/case-study-media/kumon/Kumon2022_FR_SEPT_EXPO_KV_FA.jpg">
                                    <img src="https://1dmg-website.s3.ap-southeast-1.amazonaws.com/case-study-media/kumon/Kumon2022_FR_SEPT_EXPO_KV_FA.png"/>
                                </a>
                                <a className='case-study-gallery-item left-most'
                                    data-lg-size="1280-720"
                                    data-src='https://youtube.com/watch?v=1EBoPZhmyFI'>
                                    <img src="https://1dmg-website.s3.ap-southeast-1.amazonaws.com/case-study-media/kumon/Kumon2023_FR_OCT_CONCIRGE_3_FA.png"/>
                                </a> 
                            {/* <img src={kumonLM} className='right-most'/>
                            <video controls poster={KumonImagethumb} className='left-most' preload='none'>
                                <source src="https://1dmg-website.s3.ap-southeast-1.amazonaws.com/case-study-media/kumon/Kumon2023_FR_OCT_CONCIRGE_3_FA.mp4" type="video/mp4"/>
                            </video> */}
                            </LightGallery>
                        </>}
                        article={<>
                            <h4>CHALLENGE</h4>
                            <p>To drive enrollee and franchisee acquisitions for the brand</p>
                            <h4>SOLUTION</h4>
                            <p>We implemented and optimized media strategies and placements that resulted in a significant uptick, exceeding target conversions for both enrollment and franchising.</p>
                            <div className="tags">
                                <span>Brand Strategy</span>
                                <span>Social Media Strategy</span>
                            </div>
                        </>}
                        gallery={<>
                            <LightGallery
                                speed={500}
                                plugins={[lgThumbnail, lgVideo]}
                            >
                                <a className='case-study-gallery-item top-left'
                                    href="https://1dmg-website.s3.ap-southeast-1.amazonaws.com/case-study-media/kumon/KUMON2023_OCTOBER_KV_FA.png">
                                    <img src="https://1dmg-website.s3.ap-southeast-1.amazonaws.com/case-study-media/kumon/KUMON2023_OCTOBER_KV_FA.png"/>
                                </a>
                                {/* <a className='case-study-gallery-item'
                                    href="https://1dmg-website.s3.ap-southeast-1.amazonaws.com/case-study-media/kumon/Kumon2022_FR_SEPT_EXPO_KV_FA.jpg">
                                    <img src="https://1dmg-website.s3.ap-southeast-1.amazonaws.com/case-study-media/kumon/Kumon2022_FR_SEPT_EXPO_KV_FA.png"/>
                                </a> 
                                <a className='case-study-gallery-item'
                                    href="https://1dmg-website.s3.ap-southeast-1.amazonaws.com/case-study-media/kumon/Kumon2022_SR_July_Branding_Boost_FA.jpg">
                                    <img src="https://1dmg-website.s3.ap-southeast-1.amazonaws.com/case-study-media/kumon/Kumon2022_SR_July_Branding_Boost_FA.png"/>
                                </a> */}
                                <a className='case-study-gallery-item bottom-right'
                                    href="https://1dmg-website.s3.ap-southeast-1.amazonaws.com/case-study-media/kumon/Kumon2023_FR_FebKV_FA.jpg">
                                    <img src="https://1dmg-website.s3.ap-southeast-1.amazonaws.com/case-study-media/kumon/Kumon2023_FR_FebKV_FA.png"/>
                                </a>
                                {/* <a className='case-study-gallery-item'
                                    href="https://1dmg-website.s3.ap-southeast-1.amazonaws.com/case-study-media/kumon/Kumon2023_March_Branding_Hygiene2_FA.jpg">
                                    <img src="https://1dmg-website.s3.ap-southeast-1.amazonaws.com/case-study-media/kumon/Kumon2023_March_Branding_Hygiene2_FA.png"/>
                                </a>
                                <a className='case-study-gallery-item'
                                    href="https://1dmg-website.s3.ap-southeast-1.amazonaws.com/case-study-media/kumon/Kumon2023_Sept_Hygiene_Send Message_FA.jpg">
                                    <img src="https://1dmg-website.s3.ap-southeast-1.amazonaws.com/case-study-media/kumon/Kumon2023_Sept_Hygiene_Send Message_FA.png"/>
                                </a>
                                <a className='case-study-gallery-item'
                                    data-lg-size="1280-720"
                                    data-src='{"source": [{"src":"https://1dmg-website.s3.ap-southeast-1.amazonaws.com/case-study-media/kumon/Kumon2023_FR_OCT_CONCIRGE_3_FA.mp4", "type":"video/mp4"}], "attributes": {"preload": "false", "controls": true}}'>
                                    <img src="https://1dmg-website.s3.ap-southeast-1.amazonaws.com/case-study-media/kumon/Kumon2023_FR_OCT_CONCIRGE_3_FA.png"/>
                                </a> */}
                                <a className='case-study-gallery-item bottom-left'
                                    data-lg-size="1280-720"
                                    data-src='https://youtube.com/watch?v=NuwkupSp_zE'>
                                    <img src="https://1dmg-website.s3.ap-southeast-1.amazonaws.com/case-study-media/kumon/Kumon2023_KumonConnect_BenefitStack_FA.png"/>
                                </a>
                                <a className='case-study-gallery-item top-right'
                                    data-lg-size="1280-720"
                                    data-src='https://youtube.com/watch?v=TBffpeIq8e8'>
                                    <img src="https://1dmg-website.s3.ap-southeast-1.amazonaws.com/case-study-media/kumon/Kumon2023_July_Hygiene3_Reach_FA.png"/>
                                </a>
                                {/* <a className='case-study-gallery-item'
                                    data-lg-size="1280-720"
                                    data-src='{"source": [{"src":"https://1dmg-website.s3.ap-southeast-1.amazonaws.com/case-study-media/kumon/Kumon2023_July_FR_PRIMER_4_FA.mp4", "type":"video/mp4"}], "attributes": {"preload": "false", "controls": true}}'>
                                    <img src="https://1dmg-website.s3.ap-southeast-1.amazonaws.com/case-study-media/kumon/Kumon2023_July_FR_PRIMER_4_FA.png"/>
                                </a> */}
                            </LightGallery>
                        </>}
                    />
                </Accordion.Body>
            </Accordion.Item> 
            <Accordion.Item eventKey="unmeat" id='unmeat'>
                <Accordion.Header onClick={()=>setOpenTab("unmeat")}>
                    <div className="trigger-wrapper overflow">
                        <h2>UNMEAT</h2>
                        <div className="industry">
                            <small>Industry</small><br />
                            <span>Food & Beverage</span>
                        </div>
                    </div>
                </Accordion.Header>
                <Accordion.Body>
                    <CaseStudiesSlides 
                        project={"unmeat"}
                        media={<>
                            <LightGallery
                                speed={500}
                                plugins={[lgThumbnail, lgVideo]}
                            >
                                <a className='case-study-gallery-item right-most'
                                    href="https://1dmg-website.s3.ap-southeast-1.amazonaws.com/case-study-media/unmeat/UnMeat2021_IGHighlights_FAQs_Ingredients_Burger_FA.jpg">
                                    <img src="https://1dmg-website.s3.ap-southeast-1.amazonaws.com/case-study-media/unmeat/UnMeat2021_IGHighlights_FAQs_Ingredients_Burger_FA.png"/>
                                </a>
                                <a className='case-study-gallery-item bottom-left'
                                    href="https://1dmg-website.s3.ap-southeast-1.amazonaws.com/case-study-media/unmeat/UnMeat2021_July_ProductInfo_1080x1080_FA.jpg">
                                    <img src="https://1dmg-website.s3.ap-southeast-1.amazonaws.com/case-study-media/unmeat/UnMeat2021_July_ProductInfo_1080x1080_FA.png"/>
                                </a>
                                <a className='case-study-gallery-item top-left'
                                    data-lg-size="1280-720"
                                    data-src='https://youtube.com/watch?v=ThO2fEcJmrU'>
                                    <img src="https://1dmg-website.s3.ap-southeast-1.amazonaws.com/case-study-media/unmeat/UnMeat2021_PTVCs_Burger10s_1920x1080_FA.png"/>
                                </a>
                            </LightGallery>
                            {/* <img src={unmeatRM} className='right-most'/>
                            <img src={unmeatBL} className='bottom-left'/>
                            <video controls poster={unmeatImage} className='top-left' preload='none'>
                                <source src="https://1dmg-website.s3.ap-southeast-1.amazonaws.com/case-study-media/unmeat/UnMeat2021_PTVCs_Burger10s_1920x1080_FA.mp4" type="video/mp4"/>
                            </video> */}
                        </>}
                        article={<>
                            <h4>CHALLENGE</h4>
                            <p>To launch UnMEAT online at the height of the pandemic</p>
                            <h4>SOLUTION</h4>
                            <p>We activated a virtual launch event with experiential touch points allowing the biggest local foodie influencers to taste, enjoy and promote our new line of products through e-commerce.</p>
                            <div className="tags">
                                <span className='blue'>Social Media Strategy</span>
                                <span className='blue'>Influencer Management</span>
                                <span className='blue'>Brand Strategy</span>
                            </div>
                        </>}
                        gallery={<>
                            <LightGallery
                                speed={500}
                                plugins={[lgThumbnail, lgVideo]}
                            >
                                {/* <a className='case-study-gallery-item'
                                    href="https://1dmg-website.s3.ap-southeast-1.amazonaws.com/case-study-media/unmeat/UnMeat2021_IGHighlights_FAQs_Ingredients_Burger_FA.jpg">
                                    <img src="https://1dmg-website.s3.ap-southeast-1.amazonaws.com/case-study-media/unmeat/UnMeat2021_IGHighlights_FAQs_Ingredients_Burger_FA.png"/>
                                </a>
                                <a className='case-study-gallery-item'
                                    href="https://1dmg-website.s3.ap-southeast-1.amazonaws.com/case-study-media/unmeat/UnMeat2021_July_ProductInfo_1080x1080_FA.jpg">
                                    <img src="https://1dmg-website.s3.ap-southeast-1.amazonaws.com/case-study-media/unmeat/UnMeat2021_July_ProductInfo_1080x1080_FA.png"/>
                                </a>
                                <a className='case-study-gallery-item'
                                    data-lg-size="1280-720"
                                    data-src='{"source": [{"src":"https://1dmg-website.s3.ap-southeast-1.amazonaws.com/case-study-media/unmeat/UnMeat2021_PTVCs_Burger10s_1920x1080_FA.mp4", "type":"video/mp4"}], "attributes": {"preload": "false", "controls": true}}'>
                                    <img src="https://1dmg-website.s3.ap-southeast-1.amazonaws.com/case-study-media/unmeat/UnMeat2021_PTVCs_Burger10s_1920x1080_FA.png"/>
                                </a>
                                <a className='case-study-gallery-item top-most'
                                    data-lg-size="1280-720"
                                    data-src='{"source": [{"src":"https://1dmg-website.s3.ap-southeast-1.amazonaws.com/case-study-media/unmeat/UnMeat2020_Jan_PreLaunch_OmnibusHighlight_FBIG_FA.mp4", "type":"video/mp4"}], "attributes": {"preload": "false", "controls": true}}'>
                                    <img src="https://1dmg-website.s3.ap-southeast-1.amazonaws.com/case-study-media/unmeat/UnMeat2020_Jan_PreLaunch_OmnibusHighlight_FBIG_FA.png"/>
                                </a> */}
                                <a className='case-study-gallery-item bottom-most'
                                    data-lg-size="1280-720"
                                    data-src='https://youtube.com/watch?v=qTTRnpXnZec'>
                                    <img src="https://1dmg-website.s3.ap-southeast-1.amazonaws.com/case-study-media/unmeat/UnMeat2021_EventLaunch_Zoom_CookingDemo_01.png"/>
                                </a>
                            </LightGallery>
                        </>}
                    />
                </Accordion.Body>
            </Accordion.Item>
            <Accordion.Item eventKey="babydove" id='babydove'>
                <Accordion.Header onClick={()=>setOpenTab("babydove")}>
                    <div className="trigger-wrapper">
                        <h2>BABY DOVE</h2>
                        <div className="industry">
                            <small>Industry</small><br />
                            <span>Personal Care</span>
                        </div>
                    </div>
                </Accordion.Header>
                <Accordion.Body>
                    <CaseStudiesSlides 
                        project={"babydove"}
                        media={<>
                            <LightGallery
                                speed={500}
                                plugins={[lgThumbnail, lgVideo]}
                            >
                                <a className='case-study-gallery-item top-right'
                                    href="https://1dmg-website.s3.ap-southeast-1.amazonaws.com/case-study-media/babydove/DoveSimba2018_MomZIlla_March_C.jpg">
                                    <img src="https://1dmg-website.s3.ap-southeast-1.amazonaws.com/case-study-media/babydove/DoveSimba2018_MomZIlla_March_C.png"/>
                                </a>
                                <a className='case-study-gallery-item bottom-right'
                                    data-lg-size="1280-720"
                                    data-src='https://youtube.com/watch?v=RSkH8BZSXJ8'>
                                    <img src="https://1dmg-website.s3.ap-southeast-1.amazonaws.com/case-study-media/babydove/DoveBaby2018_MoistureContent_Awareness_800x1000_Oct_FA_11082018.png"/>
                                </a> 
                                <a className='case-study-gallery-item left-most'
                                    data-lg-size="1280-720"
                                    data-src='https://youtube.com/watch?v=xBHl02Dfwtg'>
                                    <img src="https://1dmg-website.s3.ap-southeast-1.amazonaws.com/case-study-media/babydove/DOVEBABY2018_AWARENESSAD_360Video_800x1000_FA_09102018.png"/>
                                </a> 
                            </LightGallery>
                            {/* <img src={babyDoveTR} className='top-right'/>
                            <video controls poster={babyDovethumb2} preload='none' className='bottom-right'>
                                <source src="https://1dmg-website.s3.ap-southeast-1.amazonaws.com/case-study-media/babydove/DOVEBABY2018_CONTENT_JAN_Purchase_Carousel_Q1_Part_1080x1080.mp4"  type="video/mp4"/>
                            </video>
                            <video controls poster={babyDovethumb}  preload='none' className='left-most'>
                                <source src="https://1dmg-website.s3.ap-southeast-1.amazonaws.com/case-study-media/babydove/DOVEBABY2018_AWARENESSAD_360Video_800x1000_FA_09102018.mp4"  type="video/mp4"/>
                            </video> */}
                        </>}
                        article={<>
                            <h4>CHALLENGE</h4>
                            <p>To launch and introduce the Baby Dove brand to the Philippine market</p>
                            <h4>SOLUTION</h4>
                            <p> We crafted an integrated launch campaign over multiple platforms with hyper targeted executions, creating lasting awareness for a niche segment.</p>
                            <div className="tags">
                                <span>Brand Strategy</span>
                                <span>Social Media Strategy</span>
                            </div>
                        </>}
                        gallery={<>
                            <LightGallery
                                speed={500}
                                plugins={[lgThumbnail, lgVideo]}
                            >
                                {/* <a className='case-study-gallery-item'
                                    href="https://1dmg-website.s3.ap-southeast-1.amazonaws.com/case-study-media/babydove/DoveSimba2018_MomZIlla_March_C.jpg">
                                    <img src="https://1dmg-website.s3.ap-southeast-1.amazonaws.com/case-study-media/babydove/DoveSimba2018_MomZIlla_March_C.png"/>
                                </a> */}
                                <a className='case-study-gallery-item bottom'
                                    data-lg-size="1280-720"
                                    data-src='https://youtube.com/watch?v=W2TVHxMlxSs'>
                                    <img src="https://1dmg-website.s3.ap-southeast-1.amazonaws.com/case-study-media/babydove/Happy First Mother’s Day.png"/>
                                </a>
                                {/* <a className='case-study-gallery-item'
                                    data-lg-size="1280-720"
                                    data-src='{"source": [{"src":"https://1dmg-website.s3.ap-southeast-1.amazonaws.com/case-study-media/babydove/DoveBaby2018_MoistureContent_Awareness_800x1000_Oct_FA_11082018.mp4", "type":"video/mp4"}], "attributes": {"preload": "false", "controls": true}}'>
                                    <img src="https://1dmg-website.s3.ap-southeast-1.amazonaws.com/case-study-media/babydove/DoveBaby2018_MoistureContent_Awareness_800x1000_Oct_FA_11082018.png"/>
                                </a> */}
                                <a className='case-study-gallery-item right'
                                    data-lg-size="1280-720"
                                    data-src='https://youtube.com/watch?v=tGUxTbVIhCs?si=2tR0EEiw3XK8-c4c'>
                                    <img src="https://1dmg-website.s3.ap-southeast-1.amazonaws.com/case-study-media/babydove/Dove2018_BabyDove_A.png"/>
                                </a>
                                <a className='case-study-gallery-item left'
                                    data-lg-size="1280-720"
                                    data-src='https://youtube.com/watch?v=6_0w4YlbSkU'>
                                    <img src="https://1dmg-website.s3.ap-southeast-1.amazonaws.com/case-study-media/babydove/DOVEBABY2018_CONTENT_JAN_Purchase_Carousel_Q1_Part_1080x1080.png"/>
                                </a> 
                                {/* <a className='case-study-gallery-item'
                                    data-lg-size="1280-720"
                                    data-src='{"source": [{"src":"https://1dmg-website.s3.ap-southeast-1.amazonaws.com/case-study-media/babydove/DOVEBABY2018_AWARENESSAD_360Video_800x1000_FA_09102018.mp4", "type":"video/mp4"}], "attributes": {"preload": "false", "controls": true}}'>
                                    <img src="https://1dmg-website.s3.ap-southeast-1.amazonaws.com/case-study-media/babydove/DOVEBABY2018_AWARENESSAD_360Video_800x1000_FA_09102018.png"/>
                                </a> */}
                            </LightGallery>
                        </>}
                    />
                </Accordion.Body>
            </Accordion.Item>
        </Accordion>
        </>
    );
}