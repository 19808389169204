import React, {useState} from 'react';
import Modal from 'react-bootstrap/Modal';
import AWS from 'aws-sdk';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import toast, { Toaster } from 'react-hot-toast';
import * as faIcon from '@fortawesome/free-solid-svg-icons';
import S3FileUpload from 'react-s3';
import { uploadFile } from 'react-s3';
import { Autoplay, Pagination, FreeMode} from 'swiper';
import { Swiper, SwiperSlide } from 'swiper/react';
import { Navigation } from 'swiper';
import { useSwiper } from 'swiper/react';
import config from "../config";
import 'swiper/css';
import "swiper/swiper-bundle.css";
import "swiper/css/pagination";
import "swiper/css/autoplay";
import 'swiper/css/free-mode';

window.Buffer = window.Buffer || require("buffer").Buffer; 


export default function Resume(){
    const [show, setShow] = useState(false);
    const [modalLoading, setModalLoading] = useState(false);
    const [showThankYou, setShowThankYou] = useState(false);
    const handleClose = () => {setShow(false); setFileName("Click to choose a file or drag a file here")};
    const handleCloseThankYou = () => {setShowThankYou(false)};
    const [fileName, setFileName] = useState("Click to choose a file or drag a file here");
    const [applicationType, setApplicationType] = useState();
    const [fileLink, setFilelink] = useState();

    const handleShow = (e) => {
        e.preventDefault();
        e.stopPropagation();
        const appType = document.getElementById("career-type");

        if(appType.value !== ""){
            setShow(true);
            setApplicationType(appType.value);
        }else{
            toast.error("Please select an application type.")
        }

    };

    async function handleSubmit(e){
        setModalLoading(true);
        e.preventDefault();
        e.stopPropagation();
        e.target.setAttribute("disabled", "disabled");

        const form = e.target.closest("form");
        const formData = new FormData(form);

        var data = {};
        formData.forEach((value, key) => data[key] = value);
        data["action"] = "resume";
        data["application"] = applicationType;
        data["enctype"] = "multipart/form-data";

        if(data["resume"].name=="" || data["email"]=="" || data["name"]==""){
            toast.error("Please complete the form.");
            e.target.removeAttribute("disabled");
            setModalLoading(false);

            return;
        }

        if(data["resume"].type != "application/pdf"){
            toast.error('Resume must be in PDF');
            setModalLoading(false);
            e.target.removeAttribute("disabled");

            return;
        }

        var moment = require('moment');
        const file = data["resume"];
        const fileName = `${file.name.replace('.pdf', '')}_${data["name"].replace(/\s/g, '')}_${moment().format("MMDDYYYY_HHmm")}.pdf`

        const S3_BUCKET = "1dmg-website";
        const S3_DIR = "1dmg-website";
        const REGION = "ap-southeast-1";

        AWS.config.update({
            accessKeyId: process.env.REACT_APP_AWS_ACCESS_KEY,
            secretAccessKey: process.env.REACT_APP_AWS_SECRET_KEY,
        });

        const s3 = new AWS.S3({
            params: { Bucket: S3_BUCKET },
            region: REGION,
        });
      
        const params = {
            Bucket: S3_BUCKET,
            Key: `resume/${fileName}`,
            Body: file,
        };

        s3.upload(params, async (err, s3data) => {
            if (err) {
                toast.error('File failed to upload. Please try again.');
                e.target.removeAttribute("disabled");
                setModalLoading(false);
                return
            } else {
                setFilelink(s3data["Location"].toString());
                data["link"] = s3data["Location"].toString();
                
                var json = JSON.stringify(data);
                console.log(data, json);
        
                const response = await fetch(
                    "https://gm29e13mn3.execute-api.ap-southeast-1.amazonaws.com/default/DMGEmail", {
                    method: 'POST',    
                    headers: {
                        'Content-Type': 'application/json',
                        "Content-Type": `multipart/form-data: boundary=add-random-characters`
                    },        
                    body: json
                });
                const res = await response.json();
        
                if(res == "SUCCESS"){
                    setModalLoading(false);
                    setShow(false);
                    setShowThankYou(true);
                    setFileName("Click to choose a file or drag a file here");
                }else{
                    toast.error('Uh oh, something went wrong. Please try again.');
        
                    setModalLoading(false);
                    e.target.removeAttribute("disabled");
                }
                // setModalLoading(false);
                // e.target.removeAttribute("disabled");
            }
        });
    };


    function handleChange(event) {
        setFileName(event.target.files[0].name);
    }

	return (
        <>
            <form className="form">
                <span className="select-wrapper">
                    <select className='select-career' name="" id="career-type">
                        <option value="" disabled defaultValue>Select application</option>
                        <option value="Employment">employment</option>
                        <option value="Internship">internship</option>
                    </select>
                </span>
                <br />
                <button className="cta button cta-resume" onClick={(e)=>handleShow(e)}>
                    Send resume
                </button>
            </form>
            <Modal show={show} className={`modal-resume ${modalLoading ? "modal-loading" : ""} `} size='md'>
                <span onClick={handleClose} className='close-button'>
                    <FontAwesomeIcon icon={faIcon.faClose}/>
                </span>
                <Modal.Body>
                    <div className="resume-wrapper">
                        <form action="" className='form-resume'>
                            <div className="formGroup">
                                <label htmlFor="name">Name</label>
                                <input type="text" name='name' placeholder='John Dela Cruz' required/>
                            </div>
                            <div className="formGroup">
                                <label htmlFor="email">Email</label>
                                <input type="email" name='email' placeholder='john@email.com' required/>
                            </div>
                            <div className="formGroup">
                                <label htmlFor="resume">Upload resume <br /><small>File: PDF</small></label>
                                <div className="input-file" onChange={handleChange}>
                                    <input type="file" name='resume' placeholder='' accept="application/pdf" required/>
                                    <span className='icon'>
                                        <FontAwesomeIcon icon={faIcon.faUpload}/>
                                    </span>
                                    <span>{fileName}</span>
                                </div>
                            </div>
                            <div className="buttons mt-4">
                                <button className='button-blue-outline' onClick={(e)=>{handleSubmit(e)}}>Submit resume</button>
                            </div>
                        </form>
                    </div>
                </Modal.Body>
            </Modal>
            <Modal show={showThankYou} className='modal-thankyou' size='md'>
                <span onClick={handleCloseThankYou} className='close-button'>
                    <FontAwesomeIcon icon={faIcon.faClose}/>
                </span>
                <Modal.Body>
                    <div className="thank-you">
                        <span className='check'>
                            <FontAwesomeIcon icon={faIcon.faCheck}/>
                        </span>
                        <h1>
                            Your resume has been submitted!
                            <br />Thank you!
                        </h1>
                        <div className="buttons mt-4">
                            <button className='button-blue-outline' onClick={handleCloseThankYou}>Close</button>
                        </div>
                    </div>
                </Modal.Body>
            </Modal>
        </>
	);	
};