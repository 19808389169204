import React from 'react';
import { useAppContext } from '../libs/contextLib';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import * as faIcon from '@fortawesome/free-solid-svg-icons';
import * as faBrands from "@fortawesome/free-brands-svg-icons";

import logoBlue from "../assets/images/1dmgLogo-blue.png";

export default function Footer() {
	const { isMobileView } = useAppContext();
    return(
        <>

            {/* <section className='footer'>
                <div className="contact-banner">
                    <h1>You could be the one <br />we've been looking for</h1>
                    <img src={mascot} className='mascot'/>
                </div> 
                 <div className="brief">
                    <button className="button-brief">
                        <span className="text">
                            Send us a brief 
                        </span>
                        <span className="icon"><FontAwesomeIcon icon={faIcon.faEnvelope}/>
                    </span>
                    </button>
                </div>
                <div className="contact-details">
                    <div className="site-map">
                        <ul className='footer-nav'>
                            <li><a href="">Home</a></li>
                            <li><a href="">About Us</a></li>
                            <li><a href="">Case Studies</a></li>
                            <li><a href="">Services</a></li>
                            <li><a href="">Partners</a></li>
                            <li><a href="">Careers</a></li>
                            <li><a href="">Contact Us</a></li>
                        </ul>
                    </div>
                    <div className="visit-us">
                        <p>CONNECT WITH US!</p>
                        <div className="socials">
                            <a href=""><FontAwesomeIcon icon={faBrands.faFacebookF}/></a>
                            <a href=""><FontAwesomeIcon icon={faBrands.faLinkedinIn}/></a>
                            <a href=""><FontAwesomeIcon icon={faBrands.faTwitter}/></a>
                            <a href=""><FontAwesomeIcon icon={faBrands.faWhatsapp}/></a>
                            <a href=""><FontAwesomeIcon icon={faBrands.faFacebookMessenger}/></a>
                            <a href=""><FontAwesomeIcon icon={faBrands.faInstagram}/></a>
                            <a href=""><FontAwesomeIcon icon={faBrands.faYoutube}/></a>
                        </div>
                        <p className="address mt-3">160 Pasig Blvd, Pasig, Metro Manila</p>
                        <a className="address" href="mailto:info@1dmg.com.ph">
                            <p>info@1dmg.com.ph</p>
                        </a>
                    </div>
                </div>
                <footer>
                    <img className='header-logo' src={logoBlue} alt="1DMG Logo Blue"/>
                    <div>

                        <p>Copyright ©  2023 WishCraft Group of Companies. All rights reserved.</p>
                    </div>
                </footer>
            </section> */}
            <section className='footer'>
                <div className="contact-details">
                    <div className="email-phone">
                        <a href="mailto:info@1dmg.com.ph">
                            info@1dmg.com.ph
                        </a>
                        {/* <a href="tel:+639123456789">
                            +639123456789
                        </a> */}
                    </div>
                    <div className="connect-with-us">
                        <p>CONNECT WITH US!</p>
                        <div className="socials">
                            <a href="https://www.facebook.com/onedmg/"><FontAwesomeIcon icon={faBrands.faFacebookF}/></a>
                            <a href="https://www.linkedin.com/company/1-digital-media-group-1dmg-"><FontAwesomeIcon icon={faBrands.faLinkedinIn}/></a>
                            <a href="https://www.instagram.com/1dmgph"><FontAwesomeIcon icon={faBrands.faInstagram}/></a>
                        </div>
                    </div>
                </div>
                <footer>
                    <a href="/">
                        <img className='header-logo' src={logoBlue} alt="1DMG Logo Blue"/>
                    </a>
                    <div>
                        <p className="address mb-2 text-bold">160 Pasig Blvd, Pasig, Metro Manila</p>
                        {!isMobileView && <p>Copyright ©  2023 WishCraft Group of Companies. All rights reserved.</p>}
                    </div>
                </footer>
                {isMobileView && <p className='mobile-copyright'>Copyright ©  2023 WishCraft Group of Companies. All rights reserved.</p>}
            </section>
        </>
    );
}