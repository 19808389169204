import React, {useState, useEffect} from 'react';
import Modal from 'react-bootstrap/Modal';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import toast, { Toaster } from 'react-hot-toast';
import * as faIcon from '@fortawesome/free-solid-svg-icons';
import { Autoplay, Pagination, FreeMode} from 'swiper';
import { Swiper, SwiperSlide } from 'swiper/react';
import { Navigation } from 'swiper';
import { useSwiper } from 'swiper/react';
import 'swiper/css';
import "swiper/swiper-bundle.css";
import "swiper/css/pagination";
import "swiper/css/autoplay";
import 'swiper/css/free-mode';

import briefCTA from "../assets/images/brief-beveled-button.png";

export default function Brief(props){
    const [briefContent, setBriefContent] = useState("");
    const [briefSentence, setBriefSentence] = useState("");
    const [showThankYou, setShowThankYou] = useState(false);
    const handleCloseThankYou = () => {setShowThankYou(false)};
    const [modalLoading, setModalLoading] = useState(false);
    const [show, setShow] = useState(false);
    const handleClose = () => {
        setShow(false);
        resetBrief();
    };
    const handleShow = () => setShow(true);

    const [swiper, setSwiper] = React.useState(null);
    const nextSlide = () => {swiper.slideNext();};
    const prevSlide = () => {swiper.slidePrev();};

    const [briefGet, setBriefGet] = useState("");
    const [briefTo, setBriefTo] = useState("");
    const [briefBrand, setBriefBrand] = useState("");
    const [briefThrough, setBriefThrough] = useState([]);
    const [briefThroughString, setBriefThroughString] = useState();

    function resetBrief() {
        setBriefGet("");
        setBriefTo("");
        setBriefBrand("");
        setBriefThrough([]);
    }

    function handleBriefThrough(data, e){
        var arr = briefThrough;
        var el = e.target;
        console.log(el)
        if(arr.includes(data) == false){
            arr.push(data);
            setBriefThrough(arr); 
            el.classList.add("active");
        }else if(arr.includes(data)){
            arr.splice(arr.indexOf(data), 1);
            setBriefThrough(arr);
            el.classList.remove("active");
        }
    }

    async function generateBrief() {
        setModalLoading(true);
        var brief = `Get ${briefGet} to ${briefTo} ${briefBrand} through `;

        if(briefThrough.length > 1){
            var placeholder = "";
            briefThrough.forEach((val, index)=>{
                var a = "";
                if(briefThrough.length === index + 1){
                    a = `and ${val}.`;
                }else{
                    a = `${val}, `;
                }
                placeholder += a;
            });

            setBriefThroughString(placeholder);
            brief = brief + placeholder;
        }else{
            setBriefThroughString(briefThrough+".");
            brief = brief + briefThrough + ".";
        }

        setBriefSentence(brief);

        var data = {};
        data["brief"] = brief;
        var json = JSON.stringify(data);
        const response = await fetch(
            "https://fzr7jmcno9.execute-api.ap-southeast-1.amazonaws.com/prod/generateBrief", {
            method: 'POST',    
            headers: {
                'Content-Type': 'application/json'
            },        
            body: json
        });
        const res = await response.json();

        if(res.statusCode === 200){
            toast.success("Brief generated");
            // setBriefContent(res.message.content);
            setBriefContent(res.body.choices[0].message.content);
            setTimeout(() => {
                swiper.slideNext();
                setModalLoading(false);
            }, 500);
        }else{
            toast.error("Uh oh, somethign went wrong")
            setTimeout(() => {
                setModalLoading(false);
            }, 500);
        }
    }

    async function handleSubmitBrief(e) {
        e.preventDefault();
        e.stopPropagation();
        e.target.setAttribute("disabled", "disabled");

        setModalLoading(true);

        const form = e.target.closest(".brief-details").querySelector("form");
        const formData = new FormData(form);

        var data = {};
        formData.forEach((value, key) => data[key] = value);
        data["action"] = "discover";
        data["brief"] = briefContent;

        var json = JSON.stringify(data);

        console.log(data, json);

        if(data["name"]=="" || data["email"]=="" || data["websiteLink"]=="" || data["socialMediaLink"]==""){
            toast.error("Please complete the form.");
            e.target.removeAttribute("disabled");
            setModalLoading(false);
        }else{
            const response = await fetch(
                "https://gm29e13mn3.execute-api.ap-southeast-1.amazonaws.com/default/DMGEmail", {
                method: 'POST',    
                headers: {
                    'Content-Type': 'application/json'
                },        
                body: json
            });
            const res = await response.json();
            console.log(res);
    
            if(res == "SUCCESS"){
                // toast.success('Thank you! We received your application!');
                setShow(false);
                setShowThankYou(true);
            }else{
                toast.error('Uh oh, something went wrong. Please try again.');
    
                setModalLoading(false);
                e.target.removeAttribute("disabled");
            }
        }
    }

    useEffect(() => {

    }, [briefThrough])
    

	return (
        <>
            {props.for == "header" ? 
                <button className="cta button cta-brief" onClick={handleShow}>
                    Send us a brief
                </button>
            :
                <button className="cta button cta-brief img" onClick={handleShow}>
                    <img src={briefCTA}/>
                </button>
            }
            <Modal show={show} className={`modal-brief ${modalLoading ?"modal-loading" : ""} `} size='lg'>
                <span onClick={handleClose} className='close-button'>
                    <FontAwesomeIcon icon={faIcon.faClose}/>
                </span>
                <Modal.Body>
                    <div className="brief-wrapper">
                        <Swiper
                            spaceBetween={50}
                            slidesPerView={1}
                            autoHeight={true}
                            simulateTouch={false}
                            noSwiping={true}
                            onSwiper={(s) => {
                                setSwiper(s);
                            }}
                            >
                            <SwiperSlide>
                                <div className="brief-generator-form">
                                    <div className="brief-item brief-get" data-get={briefGet}>
                                        <label>
                                            GET <span className="sub-label">Identify the target market</span>
                                        </label>
                                        <ul>
                                            <li onClick={()=>setBriefGet("Moms")} className={`${(briefGet=="Moms") ? "active" : ""}`} >Moms</li>
                                            <li onClick={()=>setBriefGet("Teens")} className={`${(briefGet=="Teens") ? "active" : ""}`} >Teens</li>
                                            <li onClick={()=>setBriefGet("Men")} className={`${(briefGet=="Men") ? "active" : ""}`} >Men</li>
                                            <li onClick={()=>setBriefGet("Jobbers")} className={`${(briefGet=="Jobbers") ? "active" : ""}`} >Jobbers</li>
                                            <li onClick={()=>setBriefGet("GenZ")} className={`${(briefGet=="GenZ") ? "active" : ""}`} >GenZ</li>
                                            <li onClick={()=>setBriefGet("Millennials")} className={`${(briefGet=="Millennials") ? "active" : ""}`} >Millennials</li>
                                            <li onClick={()=>setBriefGet("Seniors")} className={`${(briefGet=="Seniors") ? "active" : ""}`} >Seniors</li>
                                        </ul>
                                    </div>
                                    <div className="brief-item brief-to" data-to={briefTo}>
                                        <label>
                                            TO <span className="sub-label">Define the action</span>
                                        </label>
                                        <ul>
                                            <li onClick={()=>setBriefTo("discover")} className={`${(briefTo=="discover") ? "active" : ""}`}>discover </li>
                                            <li onClick={()=>setBriefTo("try")} className={`${(briefTo=="try") ? "active" : ""}`}>try </li>
                                            <li onClick={()=>setBriefTo("purchase")} className={`${(briefTo=="purchase") ? "active" : ""}`}>purchase</li>
                                            <li onClick={()=>setBriefTo("learn")} className={`${(briefTo=="learn about") ? "active" : ""}`}>learn about</li>
                                            <li onClick={()=>setBriefTo("re-purchase")} className={`${(briefTo=="re-purchase") ? "active" : ""}`}>re-purchase </li>
                                            <li onClick={()=>setBriefTo("engage")} className={`${(briefTo=="engage") ? "active" : ""}`}>engage</li>
                                            <li onClick={()=>setBriefTo("consider")} className={`${(briefTo=="consider") ? "active" : ""}`}>consider</li>
                                            <li onClick={()=>setBriefTo("experience")} className={`${(briefTo=="experience") ? "active" : ""}`}>experience</li>
                                        </ul>
                                    </div>
                                    <div className="brief-item brief-brand" data-brand={briefBrand}>
                                        <label>
                                            YOUR BRAND <span className="sub-label">Name your company/brand</span>
                                        </label>
                                        <input type="text" onChange={(event)=>setBriefBrand(event.target.value)}/>
                                    </div>
                                    <div className="brief-item brief-through"  data-through={briefThrough}>
                                        <label>
                                            THROUGH <span className="sub-label">Pick channels (can be multiple)</span>
                                        </label>
                                        <ul>
                                            <li onClick={(e)=>{handleBriefThrough("a website", e);}} >a website</li>
                                            <li onClick={(e)=>{handleBriefThrough("tiktok", e);}} >tiktok</li>
                                            <li onClick={(e)=>{handleBriefThrough("brand activation", e); }} >brand activation</li>
                                            <li onClick={(e)=>{handleBriefThrough("social media content", e)}} >social media content</li>
                                            <li onClick={(e)=>{handleBriefThrough("article", e); }} >article</li>
                                            <li onClick={(e)=>{handleBriefThrough("influencers", e); }} >influencers</li>
                                            <li onClick={(e)=>{handleBriefThrough("online video", e); }} >online video</li>
                                            <li onClick={(e)=>{handleBriefThrough("email", e); }} >email</li>
                                            <li onClick={(e)=>{handleBriefThrough("SMS event", e); }} >SMS event</li>
                                            <li onClick={(e)=>{handleBriefThrough("out of home", e); }} >out of home</li>
                                            <li onClick={(e)=>{handleBriefThrough("radio", e); }} >radio</li>
                                            <li onClick={(e)=>{handleBriefThrough("print", e); }} >print</li>
                                        </ul>
                                    </div>
                                    <div className="buttons">
                                        <button className='button-blue-outline' onClick={generateBrief} disabled={(briefGet !== "" && briefTo !== "" && briefBrand !== "" && briefThrough !== "") ? false : true}>Generate the brief</button>
                                    </div>
                                </div>
                            </SwiperSlide>
                            <SwiperSlide>  
                                <div className="brief-sentence">
                                    <h1>
                                        "Get <span className="highlight">{briefGet}</span>  to {briefTo} <span className="highlight">{briefBrand}</span> through {briefThroughString}"
                                    </h1>

                                    <label>
                                        Generated Brief <span className="sub-label">Edit your generated brief here.</span>
                                    </label>
                                    <form className='mt-2'>
                                        <textarea name="generatedBrief" id="generatedBrief" cols="30" rows="10" value={briefContent} onChange={e => setBriefContent(e.target.value)}></textarea>
                                    </form>
                                    <div className="buttons">
                                        <button className='button-blue-outline button-blue-outline-no-outline' onClick={prevSlide}>Back</button>
                                        <button className='button-blue-outline' onClick={()=>{nextSlide();}}>Send the brief</button>
                                    </div>
                                </div>
                            </SwiperSlide>
                            <SwiperSlide>  
                                <div className="brief-details">

                                    <div className='final-brief-sentence'>
                                        {briefContent}
                                    </div>
                                    <form action="" className='form-brief'>
                                        <div className="formGroup">
                                            <label htmlFor="name">Name</label>
                                            <input type="text" name='name' placeholder='John Dela Cruz'/>
                                        </div>
                                        <div className="formGroup">
                                            <label htmlFor="email">Email</label>
                                            <input type="email" name='email' placeholder='john@email.com'/>
                                        </div>
                                        <div className="formGroup">
                                            <label htmlFor="website">Website link</label>
                                            <input type="text" name='website' placeholder='www.lorem.com'/>
                                        </div>
                                        <div className="formGroup">
                                            <label htmlFor="social">Social media link</label>
                                            <input type="text" name='social' placeholder='@socialaccount'/>
                                        </div>
                                    </form>
                                    <div className="buttons">
                                        <button className='button-blue-outline button-blue-outline-no-outline' onClick={prevSlide}>Back</button>
                                        <button className='button-blue-outline' onClick={(e)=>handleSubmitBrief(e)}>Submit the brief</button>
                                    </div>
                                </div>
                            </SwiperSlide>
                        </Swiper>
                    </div>
                </Modal.Body>
            </Modal>
            <Modal show={showThankYou} className='modal-thankyou' size='md'>
                <span onClick={handleCloseThankYou} className='close-button'>
                    <FontAwesomeIcon icon={faIcon.faClose}/>
                </span>
                <Modal.Body>
                    <div className="thank-you">
                        <span className='check'>
                            <FontAwesomeIcon icon={faIcon.faCheck}/>
                        </span>
                        <h1>
                            Your brief has been submitted!
                            <br />Thank you!
                        </h1>
                        <div className="buttons mt-4">
                            <button className='button-blue-outline' onClick={handleCloseThankYou}>Close</button>
                        </div>
                    </div>
                </Modal.Body>
            </Modal>
        </>
	);	
};