import React, { useState, useEffect, Suspense, lazy } from 'react';
import logo from './logo.svg';
import './App.css';
import AppRoutes from "./Router";
// import "./assets/styles/fonts.css";
import "./assets/styles/animate.min.css";
import "./assets/styles/layout.scss";
import "./assets/styles/main.scss";
import 'bootstrap/dist/css/bootstrap.min.css';
import Header from "./components/Header";
import Footer from "./components/Footer";
import { AppContext } from './libs/contextLib';
import toast, { Toaster } from 'react-hot-toast';

function App() {
    const [windowDimension, setWindowDimension] = useState(window.innerWidth);
    const [isMobileView, setIsMobileView] = useState(windowDimension <= 950);
    const [hashVal, setHashVal] = useState();
    // const [isMobile, setIsMobile] = useState(windowDimension <= 950);
    // const isMobile = windowDimension <= 950;

    useEffect(() => {
		setWindowDimension(window.innerWidth);
        setIsMobileView(window.innerWidth <= 950);
		function handleResize() {
			setWindowDimension(window.innerWidth);
            setIsMobileView(window.innerWidth <= 950);
		}
		window.addEventListener('resize', handleResize);
		return () => window.removeEventListener('resize', handleResize);
        
    }, [windowDimension, isMobileView, hashVal])
    

    window.addEventListener("hashchange", function (event) {
        window.scrollTo(0, 0);
    });
    return (      
        <>
                <AppContext.Provider value={{
                    isMobileView, setIsMobileView, 
                    windowDimension, setWindowDimension,
                    hashVal, setHashVal
                }}>
                <Header/>
                    <AppRoutes />   
                    <Toaster />
                <Footer/>
                </AppContext.Provider>
        </>  
    );
    
}

export default App;
