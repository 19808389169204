import React, {useEffect} from 'react';
import { BrowserRouter, Route, Routes, useLocation } from "react-router-dom"
import Home from "./containers/Home";
import { useAppContext } from '../src/libs/contextLib';

export default function Router() {

	const { hashVal, sethashVal } = useAppContext();

    useEffect(() => {
        // console.log(hash);
    }, [])
    
    return (
		<>	
        {/* <Loader/>	 */}
        <BrowserRouter>
            <Routes>								
                <Route path="/" element={<Home/>} />
            </Routes>
        </BrowserRouter>
        </>
    );
}