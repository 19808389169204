import React, {useRef, useState, useEffect} from 'react';
import { useLocation } from "react-router-dom";
import { useAppContext } from '../libs/contextLib';
import ScrollContainer from 'react-indiana-drag-scroll';
import { useDraggable } from "react-use-draggable-scroll";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import * as faIcon from '@fortawesome/free-solid-svg-icons';
import * as Bootstrap from "react-bootstrap";
import Accordion from 'react-bootstrap/Accordion';
import { Autoplay, Pagination, FreeMode} from 'swiper';
import { Swiper, SwiperSlide } from 'swiper/react';
import { Navigation } from 'swiper';
import { useSwiper } from 'swiper/react';
import 'swiper/css';
import "swiper/swiper-bundle.css";
import "swiper/css/pagination";
import "swiper/css/autoplay";
import 'swiper/css/free-mode';
// import Loader from '../components/Loader';

import Services from '../components/Services';
import Brief from '../components/Brief';

// import "../assets/styles/Home.scss";

import "../assets/styles/containers/Home.scss";
import northie from "../assets/images/northie-placeholder.png";
import mascot from "../assets/images/mascot.png";
import logoBlue from "../assets/images/1dmgLogo-blue.png";

// video background
// import northieDesktop from "https://1dmg-website.s3.ap-southeast-1.amazonaws.com/videos/Desktop.mp4";
// import northieMobile from "https://1dmg-website.s3.ap-southeast-1.amazonaws.com/videos/Mobile.mp4";

// our services
// import servicesMarketAnalysis from "../assets/images/ourService-marketAnalysis.png";
// import servicesBrandStrategy from "../assets/images/ourService-brandStrategy.png";
// import servicesWebDevelopment from "../assets/images/ourService-webDevelopment.png";
// import servicesUiUxDesign from "../assets/images/ourService-uiUxDesign.png";
// import servicesProductManagement from "../assets/images/ourService-productManagement.png";
// import servicesSocialMediaMarketing from "../assets/images/ourService-socialMediaMarketing.png";

// import serviceStrategy from "../assets/images/service-strategy.png";
// import serviceDesign from "../assets/images/service-design.png";
// import serviceProjectManagement from "../assets/images/service-projectManagement.png";
// import serviceVideoProduction from "../assets/images/service-videoProduction.png";
// import serviceInnovationsAndDevelopment from "../assets/images/service-innovationsAndDevelopment.png";
// import serviceInfluencermarketing from "../assets/images/service-influencerMarketing.png";
// import serviceEcommerceEnablement from "../assets/images/service-ecommerceEnablement.png";
// import serviceCustomerSupport from "../assets/images/service-customerSupport.png";

// import partnersGcash from "../assets/images/partners-gcash.png"
// import partnersKumon from "../assets/images/partners-kumon.png"
// import partnersCiti from "../assets/images/partners-citi.png"
// import partnersUnmeat from "../assets/images/partners-unmeat.png"
// import partnersCornetto from "../assets/images/partners-cornetto.png"

// import briefCTA from "../assets/images/brief-beveled-button.png";

import partners from "../assets/images/partners.jpg";
import partners1 from "../assets/images/partners-1.jpg";
import partners2 from "../assets/images/partners-2.jpg";
import partners3 from "../assets/images/partners-3.jpg";
import partners4 from "../assets/images/partners-4.jpg";
import partners5 from "../assets/images/partners-5.jpg";
import partners6 from "../assets/images/partners-6.jpg";
import partners7 from "../assets/images/partners-7.jpg";
import partners8 from "../assets/images/partners-8.jpg";
import partners9 from "../assets/images/partners-9.jpg";
import partners10 from "../assets/images/partners-10.jpg";
import Resume from '../components/Resume';
import CaseStudies from '../components/CaseStudies';

export default function Home() {
	const { windowDimension, setWindowDimension, isMobileView, setIsMobileView, } = useAppContext();	
    const [lightOn, setLightOn] = useState(false);
    const location = useLocation();
    const hash = location.hash.split("#")[1];
    const [hashVal, setHashVal] = useState(hash);

    const caseStudies = ["citi","kumon","cornetto","unmeat","babydove"];
    const services = ["strategy", "design", "project-development","innovations","influencer-marketing","ecommerce","video-production","customer-support","media-buying","tiktok"];

    const [caseStudyKey, setCaseStudyKey] = useState(caseStudies.includes(hash) ? hash : "citi");
    const [serviceKey, setServiceKey] = useState(services.includes(hash) ? hash : "strategy");


    const [swiper, setSwiper] = React.useState(null);
    const [currSlide, setCurrSlide] = React.useState(0);
    const nextSlide = () => {swiper.slideNext();};
    const prevSlide = () => {swiper.slidePrev();};



    useEffect(() => {
        setTimeout(() => {
            scrollToElement();
        }, 500);
    }, [isMobileView]);

    const scrollToElement = () => {
        const element = document.getElementById(hash);
        if (element) {
          element.scrollIntoView({ behavior: 'smooth', block: 'start' });
        }
    };

    return(
        <>
            {/* {loading && (<Loader/>)} */}
            <>
                <section className={`home-banner`} id='about'>
                    <video autoPlay muted hidden={isMobileView ? false : "hidden"}>
                        <source src="https://1dmg-website.s3.ap-southeast-1.amazonaws.com/videos/1DMG_OBP_Mobile-optimized.mp4" type="video/mp4"/>
                    </video>
                    <video autoPlay muted hidden={!isMobileView ? false : "hidden"}>
                        <source src="https://1dmg-website.s3.ap-southeast-1.amazonaws.com/videos/1DMG_OBP_Desktop-optimized.mp4" type="video/mp4"/>
                    </video>

                    <div className="headline-wrapper">
                        <h1 className="headline">
                            We bring <br /> light-bulb <br /> moments <br /> to life.
                        </h1>
                        <h3 className='subHeadline'>
                            We’re a strategic, creative solutions partner <br /> dedicated to elevating brands <br /> and crafting experiences. 
                        </h3>
                    </div>
                </section>
                <section className='partners'>
                    <div className='left'>
                        <h1>With over a decade <br />&nbsp;&nbsp;of experience</h1>
                        <h3>in handling the biggest brands <br /> in tech, finance, personal care, F&B, <br /> education and retail.</h3>
                    </div>
                    <div className='right'>
                        <div className='partner-logos slider'>
                            <Swiper
                                spaceBetween={0}
                                slidesPerView={2.75}
                                simulateTouch={false}
                                noSwiping={false}
                                allowTouchMove={false}
                                // autoHeight={(props.project=="cornetto")?true:false}
                                className='partners-slider'
                                loop={true}
                                slidesPerGroup={2}
                                breakpoints={{
                                    750: {
                                      slidesPerView: 3.75
                                    }
                                }}
                                onSwiper={(s) => {
                                    setSwiper(s);
                                }}
                                onActiveIndexChange={(s) => { 
                                    setCurrSlide(s.activeIndex);
                                }}
                                >
                                <SwiperSlide><div className="logos-wrapper"><img src={partners1}/></div></SwiperSlide>
                                <SwiperSlide><div className="logos-wrapper"><img src={partners2}/></div></SwiperSlide>
                                <SwiperSlide><div className="logos-wrapper"><img src={partners3}/></div></SwiperSlide>
                                <SwiperSlide><div className="logos-wrapper"><img src={partners4}/></div></SwiperSlide>
                                <SwiperSlide><div className="logos-wrapper"><img src={partners5}/></div></SwiperSlide>
                                <SwiperSlide><div className="logos-wrapper"><img src={partners6}/></div></SwiperSlide>
                                <SwiperSlide><div className="logos-wrapper"><img src={partners7}/></div></SwiperSlide>
                                <SwiperSlide><div className="logos-wrapper"><img src={partners8}/></div></SwiperSlide>
                                <SwiperSlide><div className="logos-wrapper"><img src={partners9}/></div></SwiperSlide>
                                <SwiperSlide><div className="logos-wrapper"><img src={partners10}/></div></SwiperSlide>
                            </Swiper>
                            <span className='arrow' id='scroll-arrow' onClick={nextSlide}><FontAwesomeIcon icon={faIcon.faArrowRight}/></span>
                        </div>
                    </div>
                </section>
                <section className="brief">
                    <div className="content-wrapper">
                        <Brief/>
                        <h3>
                            <span>We have</span>
                            <em>a full team of experts </em>
                            <span>ready to get on the case</span>
                        </h3>
                    </div>
                </section>
                <section className="case-studies" id='case-study'>
                    <h1 className="title">
                        Case Study
                    </h1>
                    <CaseStudies openTab={caseStudyKey}/>
                </section>
                <section className="services">
                    <h1 className="title">
                        Services
                    </h1>
                    <Services openTab={serviceKey}/>
                </section>
                <section className="career" id='contact'>
                    <div className="title">
                        <h3>You could</h3>
                        <h1>BE THE ONE</h1>
                        <h3>we've been looking for.</h3>
                    </div>
                    <Resume/>
                </section>
            </>
        </>
    );
}