import React, {useEffect, useState} from 'react';
import { useLocation } from "react-router-dom";
import { useAppContext } from '../libs/contextLib';
import * as Bootstrap from "react-bootstrap";
import Accordion from 'react-bootstrap/Accordion';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import * as faIcon from '@fortawesome/free-solid-svg-icons';

import logo from "../assets/images/1dmgLogo-light.png";

import iconStrategy from "../assets/images/service-icon-strategy.png";
import iconDesign from "../assets/images/service-icon-design.png";
import iconProjectDev from "../assets/images/service-icon-projdevelopment.png";
import iconInnovDev from "../assets/images/service-icon-innovedevelopment.png";
import iconInfluencer from "../assets/images/service-icon-influencermarketing.png";
import iconEcomm from "../assets/images/service-icon-eccomm.png";
import iconVideoProd from "../assets/images/service-icon-videoprod.png";
import iconCustomerSup from "../assets/images/service-icon-customersupport.png";
import iconMediaBuying from "../assets/images/service-icon-mediabuy.png";
import iconTiktok from "../assets/images/service-icon-tiktok.png";

export default function Services(props) {
	// const { isMobileView } = useAppContext();

    var key = props.openTab;
    const services = ["strategy", "design", "project-development","innovations","influencer-marketing","ecommerce","video-production","customer-support","media-buying","tiktok"]

    const { hash } = useLocation();
    const [openTab, setOpenTab] = useState(hash.split("#")[1])

    function getOffset( el ) {
        var _x = 0;
        var _y = 0;
        while( el && !isNaN( el.offsetLeft ) && !isNaN( el.offsetTop ) ) {
            _x += el.offsetLeft - el.scrollLeft;
            _y += el.offsetTop - el.scrollTop;
            el = el.offsetParent;
        }
        return { top: _y, left: _x };
    }

    useEffect(() => {
        if(hash){
            const el = document.querySelector(hash);
            setOpenTab(hash.split("#")[1]);
            setTimeout(() => {
                window.scrollTo({
                    top: getOffset(el).top - 30,
                    behavior: 'smooth'
                });
            }, 500);
        }
    }, [hash])

    useEffect(() => {
        
    }, [])
    
    return(
        <>
        <Accordion defaultActiveKey={[props.openTab]} activeKey={openTab} alwaysOpen>
            <Accordion.Item eventKey="strategy" id='strategy'>
                <Accordion.Header onClick={()=>setOpenTab("strategy")}>
                    <div className="trigger-wrapper">
                        <div className='left'>
                            <span className="icon"><img src={iconStrategy} alt="Strategy" /></span>
                            <span className="name">Strategy</span>
                        </div>
                        <div className='right'>
                            <span className="desc">We set your goals and put your plans into action</span>
                            <span className="open-close">
                                <div className="plus-minus">
                                    <div></div>
                                    <div></div>
                                </div>
                            </span>
                        </div>
                    </div>
                </Accordion.Header>
                <Accordion.Body>
                    <div className="body-wrapper">
                        <div className="filler"></div>
                        <ul className="services-list">
                            <li>Communication Strategy </li>
                            <li>Channel Strategy</li>
                            <li>Media Strategy</li>
                            <li>Brand Audit and Market Analysis </li>
                            <li>Content Marketing Strategy</li>
                        </ul>
                    </div>
                </Accordion.Body>
            </Accordion.Item>
            <Accordion.Item  eventKey="design" id='design'>
                <Accordion.Header onClick={()=>setOpenTab("design")}>
                    <div className="trigger-wrapper">
                        <div className='left'>
                            <span className="icon"><img src={iconDesign} alt="Design" /></span>
                            <span className="name">Creative Services</span>
                        </div>
                        <div className='right'>
                            <span className="desc">We visualize with form and function in mind</span>
                            <span className="open-close">
                                <div className="plus-minus">
                                    <div></div>
                                    <div></div>
                                </div>
                            </span>
                        </div>
                    </div>
                </Accordion.Header>
                <Accordion.Body>
                    <div className="body-wrapper">
                        <div className="filler"></div>
                        <ul className="services-list">
                            <li>Brand Identity Design</li>
                            <li>Copywriting</li>
                            <li>Scriptwriting</li>
                            <li>Video Production</li>
                            <li>Photography</li>
                            <li>Social Media Content</li>
                            <li>In-store Collaterals</li>
                            <li>Packaging Design</li>
                        </ul>
                    </div>
                </Accordion.Body>
            </Accordion.Item>
            <Accordion.Item  eventKey="project-development" id='project-development'>
                <Accordion.Header onClick={()=>setOpenTab("project-development")}>
                    <div className="trigger-wrapper">
                        <div className='left'>
                            <span className="icon"><img src={iconProjectDev} alt="Project Development" /></span>
                            <span className="name">Project Management</span>
                        </div>
                        <div className='right'>
                            <span className="desc">We lead your campaigns from start to finish</span>
                            <span className="open-close">
                                <div className="plus-minus">
                                    <div></div>
                                    <div></div>
                                </div>
                            </span>
                        </div>
                    </div>
                </Accordion.Header>
                <Accordion.Body>
                    <div className="body-wrapper">
                        <div className="filler"></div>
                        <ul className="services-list">
                            {/* <li>Packaging Design </li> */}
                            <li>Events</li>
                            <li>Promo Management</li>
                        </ul>
                    </div>
                </Accordion.Body>
            </Accordion.Item>
            <Accordion.Item  eventKey="innovations" id='innovations'>
                <Accordion.Header onClick={()=>setOpenTab("project-development")}>
                    <div className="trigger-wrapper">
                        <div className='left'>
                            <span className="icon"><img src={iconInnovDev} alt="Innovations & Development" /></span>
                            <span className="name">Innovations & Development</span>
                        </div>
                        <div className='right'>
                            <span className="desc">We design your digital and IRL experiences</span>
                            <span className="open-close">
                                <div className="plus-minus">
                                    <div></div>
                                    <div></div>
                                </div>
                            </span>
                        </div>
                    </div>
                </Accordion.Header>
                <Accordion.Body>
                    <div className="body-wrapper">
                        <div className="filler"></div>
                        <ul className="services-list">
                            <li>Website Design (UI/UX)</li>
                            <li>Website Development</li>
                            <li>Search Engine Optimization</li>
                            <li>App Development</li>
                            <li>Game Development</li>
                            <li>CRM</li>
                            <li>Email and SMS Marketing</li>
                            <li>Chatbot Development</li>
                            <li>Interactive Displays</li>
                            <li>Web Hosting</li>
                        </ul>
                    </div>
                </Accordion.Body>
            </Accordion.Item>
            <Accordion.Item  eventKey="influencer-marketing" id='influencer-marketing'>
                <Accordion.Header onClick={()=>setOpenTab("influencer-marketing")}>
                    <div className="trigger-wrapper">
                        <div className='left'>
                            <span className="icon"><img src={iconInfluencer} alt="Influencer Marketing" /></span>
                            <span className="name">Influencer Marketing</span>
                        </div>
                        <div className='right'>
                            <span className="desc">We collaborate with the top content creators</span>
                            <span className="open-close">
                                <div className="plus-minus">
                                    <div></div>
                                    <div></div>
                                </div>
                            </span>
                        </div>
                    </div>
                </Accordion.Header>
                <Accordion.Body>
                    <div className="body-wrapper">
                        <div className="filler"></div>
                        <ul className="services-list">
                            <li>Influencer Engagement </li>
                            <li>Product Seeding</li>
                            <li>Press Release</li>
                        </ul>
                    </div>
                </Accordion.Body>
            </Accordion.Item>
            <Accordion.Item eventKey="ecommerce" id='ecommerce'>
                <Accordion.Header onClick={()=>setOpenTab("ecommerce")}>
                    <div className="trigger-wrapper">
                        <div className='left'>
                            <span className="icon"><img src={iconEcomm} alt="E-commerce Enablement" /></span>
                            <span className="name">E-commerce Enablement</span>
                        </div>
                        <div className='right'>
                            <span className="desc">We set up and get your business going online</span>
                            <span className="open-close">
                                <div className="plus-minus">
                                    <div></div>
                                    <div></div>
                                </div>
                            </span>
                        </div>
                    </div>
                </Accordion.Header>
                <Accordion.Body>
                    <div className="body-wrapper">
                        <div className="filler"></div>
                        <ul className="services-list">
                            <li>E-commerce Strategy</li>
                            <li>Live Commerce</li>
                            <li>E-commerce Platform Setup</li>
                            <li>Customer Support</li>
                        </ul>
                    </div>
                </Accordion.Body>
            </Accordion.Item>
            <Accordion.Item  eventKey="video-production" id='video-production'>
                <Accordion.Header onClick={()=>setOpenTab("video-production")}>
                    <div className="trigger-wrapper">
                        <div className='left'>
                            <span className="icon"><img src={iconVideoProd} alt="Video Production" /></span>
                            <span className="name">Video Production</span>
                        </div>
                        <div className='right'>
                            <span className="desc">We craft your message to fit every screen</span>
                            <span className="open-close">
                                <div className="plus-minus">
                                    <div></div>
                                    <div></div>
                                </div>
                            </span>
                        </div>
                    </div>
                </Accordion.Header>
                <Accordion.Body>
                    <div className="body-wrapper">
                        <div className="filler"></div>
                        <ul className="services-list">
                            
                        </ul>
                    </div>
                </Accordion.Body>
            </Accordion.Item>
            <Accordion.Item  eventKey="customer-support" id='customer-support'>
                <Accordion.Header onClick={()=>setOpenTab("customer-support")}>
                    <div className="trigger-wrapper">
                        <div className='left'>
                            <span className="icon"><img src={iconCustomerSup} alt="Customer Support" /></span>
                            <span className="name">Customer Support</span>
                        </div>
                        <div className='right'>
                            <span className="desc">We look after and connect with your customers for you</span>
                            <span className="open-close">
                                <div className="plus-minus">
                                    <div></div>
                                    <div></div>
                                </div>
                            </span>
                        </div>
                    </div>
                </Accordion.Header>
                <Accordion.Body>
                    <div className="body-wrapper">
                        <div className="filler"></div>
                        <ul className="services-list">
                            <li>Social Media Management</li>
                            <li>Email Support</li>
                        </ul>
                    </div>
                </Accordion.Body>
            </Accordion.Item>
            <Accordion.Item eventKey="media-buying" id='media-buying'>
                <Accordion.Header onClick={()=>setOpenTab("media-buying")}>
                    <div className="trigger-wrapper">
                        <div className='left'>
                            <span className="icon"><img src={iconMediaBuying} alt="Media Buying and Programmatic" /></span>
                            <span className="name">Media Buying and Programmatic</span>
                        </div>
                        <div className='right'>
                            <span className="desc">We launch ads that perform and meet business objectives</span>
                            <span className="open-close">
                                <div className="plus-minus">
                                    <div></div>
                                    <div></div>
                                </div>
                            </span>
                        </div>
                    </div>
                </Accordion.Header>
                <Accordion.Body>
                    <div className="body-wrapper">
                        <div className="filler"></div>
                        <ul className="services-list">
                            <li>Digital Ads</li>
                            <li>Search Engine Marketing</li>
                            <li>Programmatic</li>
                            <li>TV</li>
                            <li>Radio</li>
                            <li>Print</li>
                            <li>Advertorial Placement</li>
                            <li>Out of Home</li>
                        </ul>
                    </div>
                </Accordion.Body>
            </Accordion.Item>
            <Accordion.Item eventKey="tiktok" id='tiktok'>
                <Accordion.Header onClick={()=>setOpenTab("tiktok")}>
                    <div className="trigger-wrapper">
                        <div className='left'>
                            <span className="icon"><img src={iconTiktok} alt="Tiktok Brand Starter Pack" /></span>
                            <span className="name">Tiktok Brand Starter Pack <span className='new'>NEW</span></span>
                        </div>
                        <div className='right'>
                            <span className="desc">We propel you into the trendsetting platform</span>
                            <span className="open-close">
                                <div className="plus-minus">
                                    <div></div>
                                    <div></div>
                                </div>
                            </span>
                        </div>
                    </div>
                </Accordion.Header>
                <Accordion.Body>
                    <div className="body-wrapper">
                        <div className="filler"></div>
                        <ul className="services-list">
                            <li>Account & Tiktok Shop Setup</li>
                            <li>Content Strategy and Creation</li>
                            <li>Affiliate Marketing</li>
                            <li>Tiktok Shop Customer Support</li>
                        </ul>
                    </div>
                </Accordion.Body>
            </Accordion.Item>
        </Accordion>
        </>
    );
}